export const experienceData = [
    {
        id: 1,
        company: 'VentureDive',
        jobtitle: 'DevOps Engineer',
        startYear: 'April, 2023',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Eurus Technologies',
        jobtitle: 'DevOps Engineer',
        startYear: 'March, 2021',
        endYear: 'March, 2023'
    },
]